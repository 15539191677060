class MultiItemSelector {
  constructor(options = {}) {
    this.$bulkUpdateBtn = $(".js-bulk-select-items-btn");
    this.$bulkSelectCheckbox = $(".js-bulk-select-items-checkbox");
    this.$itemCheckboxes = $(".js-item-checkbox");
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    this.initializeBulkSelectionEvent();
    this.initializeItemCheckboxExent();
  }

  initializeBulkSelectionEvent() {
    this.$bulkSelectCheckbox.on("change", (event) => {
      if (event.target.checked) {
        this.selectUnselectRecords(true);
        this.$bulkUpdateBtn.attr("disabled", false);
      } else {
        this.selectUnselectRecords(false);
        this.$bulkUpdateBtn.attr("disabled", true);
      }

      $("[data-id='item-ids']").val("[" + this.getSelectedRecordIds() + "]");
      $("[data-id='store-item-ids']").val(
        "[" + this.getSelectedDataAttributes("data-store-item-id") + "]"
      );
    });
  }

  initializeItemCheckboxExent() {
    this.$itemCheckboxes.on("change", (event) => {
      $("[data-id='item-ids']").val("[" + this.getSelectedRecordIds() + "]");
      $("[data-id='store-item-ids']").val(
        "[" + this.getSelectedDataAttributes("data-store-item-id") + "]"
      );

      if (event.target.checked) {
        this.$bulkUpdateBtn.attr("disabled", false);
      } else {
        if ($(".js-item-checkbox:checked").length <= 0) {
          this.$bulkUpdateBtn.attr("disabled", true);
        }
      }
    });
  }

  selectUnselectRecords(selected) {
    this.$itemCheckboxes.prop("checked", selected);
  }

  getSelectedRecordIds() {
    return $(".js-item-checkbox:checked")
      .map((idx, obj) => $(obj).val())
      .get();
  }

  getSelectedDataAttributes(attrName) {
    return $(".js-item-checkbox:checked")
      .map((_, obj) => $(obj).attr(attrName))
      .get();
  }
}

$(() => {
  multiItemSelector = new MultiItemSelector({});
  multiItemSelector.init();
});
